 /*
 * @description: 主页
 * @Author: ice
 * @Date: 2020-12-22
 */
<template>
    <div class="_conteainer">
        <div class="_body_header-seat"></div>
        <div class="_body_header">
            <div class="_body_header-inner">
                <div class="_body_header-logo"></div>
                <div class="_body_header-text">掌上大学意见反馈中心</div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    components: {
    },
    data() {
        return {
        };
    },
    watch: {
    },
    computed: {
    },
    mounted () {
        console.log(process.env.VUE_APP_BASE_API);
    },
    methods: {
    },
};
</script>

<style lang="less" scoped>
@media (max-width : 500px) {
    ._body_header-seat{
        display: none;
    }
    ._body_header{
        display: none;
    }
}
@media (min-width: 500px) {
    ._body_header-seat{
        width: 100%;
        height: 60px;
    }
    ._body_header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(13, 9, 45, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._body_header-inner{
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1240px;
        box-sizing: border-box;
        padding: 0 20px;
    }
    ._body_header-logo{
        background-image: url(//cdn.zsdx.cn/featback/image/logo.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    ._body_header-text{
        font-size: 16px;
        font-weight: 500;
        color: #20A0FF;
        line-height: 18px;
    }
}
</style>